import {useState} from 'react';

type SubscriptionState = 'pending' | 'success';

/**
 * Custom hook to encapsulate the logic for the subscription success animation.
 *
 * @returns An object containing the current state and a function to change the subscription state to `'success'`.
 */
export const useSuccessAnimation = () => {
    const [subscriptionState, setSubscriptionState] = useState<SubscriptionState>('pending');

    /**
     * Function to set the `subscriptionState` to `'success'`.
     */
    const onSuccess = () => {
        setSubscriptionState('success');
    };

    return {
        onSuccess,
        subscriptionState
    };
};