import {useMutateRepository} from '@nfq/typed-next-api';

import EmailNotificationService from 'Application/services/ultra/EmailNotificationService';

/**
 * Custom hook which encapsulates the API request to request add an email to the corratec Ultra launch notification list.
 *
 * @returns An object containing the trigger for the request and the error of the request, if there is one.
 */
export const useLaunchNotify = () => {
    const {error, isMutating, trigger} = useMutateRepository<typeof EmailNotificationService.addToList>(
        'AddToList',
        EmailNotificationService.addToList,
        {revalidate: false}
    );

    return {
        addToList: trigger,
        error,
        isMutating
    };
};