import {api} from '@nfq/typed-next-api';

import type {HTTP_METHODS, MutationRepoArgs} from '@nfq/typed-next-api';
import type {addToList} from 'ApiRoutes/ultra/notify';
import type {EmailNotificationAdapter} from 'Domain/ultra/adapters/EmailNotificationAdapter';

/**
 * Email notification service class.
 *
 * This class implements the EmailNotificationAdapter interface and provides a method to sign an email address up for the launch notification.
 *
 * @implements {EmailNotificationAdapter}
 */
class EmailNotificationService implements EmailNotificationAdapter {
    /**
     * Method to sign up for the launch notification.
     *
     * This method sends a request to the backend signing the provided email address up for the launch notification.
     */
    async addToList(key: string, {arg: {body, method}}: MutationRepoArgs<{email: string}, HTTP_METHODS.POST>) {
        return api<typeof addToList>('/api/ultra/notify', {
            body,
            method
        });
    }
}

export default new EmailNotificationService();