import type {Variants} from 'framer-motion';

export const UltraLayoutTransition: Variants = {
    enter: {
        opacity: 1,
        transition: {
            duration: 0.5,
            when: 'beforeChildren'
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.5,
            when: 'afterChildren'
        }
    },
    initial: {opacity: 0}
};

export const CountdownFadeAnimation: Variants = {
    animate: {
        opacity: 1,
        transition: {
            duration: 1,
            ease: 'easeIn'
        }
    },
    exit: {opacity: 0},
    initial: {opacity: 0}
};

export const ContentSlideAnimation: Variants = {
    animate: {
        opacity: 1,
        transition: {
            delay: 0,
            duration: 0.5,
            ease: 'easeInOut'
        },
        y: 0
    },
    exit: {
        opacity: 0,
        y: '100%'
    },
    initial: {
        opacity: 0,
        y: '100%'
    }
};

export const ContentSlideAnimationDelayed: Variants = {
    animate: {
        opacity: 1,
        transition: {
            delay: 0.6,
            duration: 0.5,
            ease: 'easeInOut'
        },
        y: 0
    },
    exit: {
        opacity: 0,
        y: '100%'
    },
    initial: {
        opacity: 0,
        y: '100%'
    }
};

export const ContentFadeAnimation: Variants = {
    animate: {
        opacity: 1,
        transition: {
            delay: 0,
            duration: 0.5,
            ease: 'easeInOut'
        }
    },
    exit: {opacity: 0},
    initial: {opacity: 0}
};

export const ContentFadeAnimationDelayed: Variants = {
    animate: {
        opacity: 1,
        transition: {
            delay: 0.6,
            duration: 0.5,
            ease: 'easeInOut'
        }
    },
    exit: {opacity: 0},
    initial: {opacity: 0}
};

export const SuccessAnimation: Variants = {
    animate: {
        opacity: 1,
        position: 'relative',
        transition: {
            duration: 0.5,
            ease: 'easeInOut'
        },
        y: 0
    },
    exit: {
        opacity: 0,
        position: 'absolute',
        transition: {
            duration: 0.5,
            ease: 'easeInOut'
        },
        y: '-50px'
    },
    initial: {
        opacity: 0,
        y: '50px'
    }
};

export const CountdownPartAnimation: Variants = {
    animate: {
        position: 'relative',
        transition: {
            duration: 0.3,
            ease: 'easeInOut'
        },
        y: 0
    },
    exit: {
        position: 'absolute',
        transition: {
            duration: 0.3,
            ease: 'easeInOut'
        },
        y: '1lh'
    },
    initial: {
        position: 'absolute',
        y: '-1lh'
    }
};

export const NotifyButtonAnimation: Variants = {
    hide: {
        opacity: 0,
        transition: {
            duration: 0.5,
            ease: 'easeIn'
        },
        y: '50%'
    },
    initial: {
        opacity: 0,
        y: '50%'
    },
    show: {
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: 'easeOut'
        },
        y: 0
    }
};

export const SubscriptionAnimation: Variants = {
    animate: {
        opacity: 1,
        position: 'relative',
        transition: {
            duration: 0.5,
            ease: 'easeIn'
        },
        y: 0
    },
    exit: {
        left: 0,
        opacity: 0,
        position: 'absolute',
        transition: {
            duration: 0.5,
            ease: 'easeIn'
        },
        y: '100%'
    },
    initial: {
        opacity: 0,
        position: 'absolute',
        y: '100%'
    }
};

export const LegalTransition: Variants = {
    animate: {
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: 'easeInOut'
        },
        y: 0
    },
    exit: {
        left: 0,
        opacity: 0,
        transition: {
            duration: 0.5,
            ease: 'easeInOut'
        },
        y: '50px'
    },
    initial: {
        opacity: 0,
        y: '50px'
    }
};

export const DropdownTransition: Variants = {
    animate: {
        opacity: 1,
        y: 0
    },
    exit: {
        opacity: 0,
        y: -10
    },
    initial: {
        opacity: 0,
        y: -10
    }
};

export const NavigationTransition: Variants = {
    animate: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
            staggerDirection: 1,
            when: 'beforeChildren'
        },
        y: 0
    },
    exit: {
        opacity: 0,
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1,
            when: 'afterChildren'
        },
        y: -10
    },
    initial: {
        opacity: 0,
        y: -10
    }
};

export const NavigationItemTransition: Variants = {
    animate: {opacity: 1},
    exit: {opacity: 0},
    initial: {opacity: 0}
};

export const VideoFadeAnimation: Variants = {
    hide: {opacity: 0},
    initial: {opacity: 0},
    show: {
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: 'easeIn'
        }
    }
};

export const AccordionAnimation: Variants = {
    hidden: {height: 0},
    visible: {
        height: 'auto',
        transition: {
            duration: 0.5,
            ease: 'easeInOut'
        }
    }
};