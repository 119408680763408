import {Container} from '@nfq/react-grid';
import {AnimatePresence, m as motion} from 'framer-motion';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {UltraLayoutTransition} from 'UI/animations/ultra';

import type {WithChildren} from 'types/global';

interface ComponentProps {
    title: string;
}

/**
 * The `PageLayout` component provides a consistend layout for the corratec Ultra campaign pages.
 *
 * @param props          The component props.
 * @param props.children The children to render inside the layout.
 * @param props.title    The translation key for the page title.
 * @returns The page layout component.
 */
const UltraPageLayout = ({children, title}: WithChildren<ComponentProps>) => {
    const {t} = useTranslation('ultra', {keyPrefix: 'teaser'});

    const router = useRouter();

    return (
        <Wrapper>
            <Head>
                {/* @ts-expect-error */}
                <title>{t(`titles.${title}`)}</title>
                <meta content="#000" name="theme-color" />
                <script
                    referrerPolicy="origin"
                    src="https://cloud.ccm19.de/app.js?apiKey=a5e51fba961c6165aa3e8826f69d75ca241e366180509042&amp;domain=65f031ec8f7917f77a0d77b2"
                />
                <script
                    data-ccm-loader-group="analytics"
                    data-ccm-loader-src="https://www.googletagmanager.com/gtag/js?id=G-CZLJ30NFB3"
                    type="text/x-ccm-loader"
                />
                <script
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-CZLJ30NFB3');
                    `
                    }}
                    data-ccm-loader-group="analytics"
                    type="text/x-ccm-loader"
                />
            </Head>
            <AnimatePresence mode="wait">
                <Content
                    key={router.pathname}
                    animate="enter"
                    exit="exit"
                    initial="initial"
                    variants={UltraLayoutTransition}
                    hasNoPadding
                    isFluid
                >
                    {children}
                </Content>
            </AnimatePresence>
        </Wrapper>
    );
};

const Wrapper = styled(motion.div)`
    background: #000;
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
`;

const Content = styled(motion(Container))`
    display: flex;
    flex: 2 0 auto;
    flex-direction: column;
`;

UltraPageLayout.displayName = 'UltraPageLayout';

export {UltraPageLayout};