import type {FormEvent} from 'react';

import {HTTP_METHODS} from '@nfq/typed-next-api';

import {useLaunchNotify} from 'Application/useCases/ultra/useLaunchNotify';

import type {RequestError} from '@nfq/typed-next-api';
import type {addToList as addToListRoute} from 'ApiRoutes/ultra/notify';

/**
 * Custom hook that encapsulates the logic of the notify form submission.
 *
 * @param onSuccess The callback to be executed when the form submit was successful.
 * @returns An object containing the `onSubmit` handler and the error of the request, an `isMutating` property and a
 * boolean indicating whether the form has been successfully submitted.
 */
export const useSubmitSubscriptionForm = (onSuccess: () => void) => {
    const {addToList, error, isMutating} = useLaunchNotify();

    /**
     * Submit handler for the notify form.
     *
     * @param e The submit `FormEvent`.
     */
    const handleSubmitNotifyForm = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const email = ((e.target as HTMLFormElement).elements.namedItem('email') as HTMLInputElement).value;

        try {
            const res = await addToList({
                body: {email},
                method: HTTP_METHODS.POST
            });

            if (res) {
                onSuccess();
            }
        } catch (err) {
        }
    };

    return {
        error: error as (RequestError<typeof addToListRoute> | undefined),
        handleSubmitNotifyForm,
        isMutating
    };
};