import styled from 'styled-components';

/**
 * Component to add text which is only visible to screenreaders.
 */
export const SROnly = styled.span`
    border-width: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;